<template>
  <div class="card border-0 bg-light shadow-sm mb-4" id="tax-rates">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p class="alert alert-primary">
              Set up custom tax rates for use on services & client orders.
            </p>

            <div class="card mb-3">
              <table class="table table-hover mb-0">
                <thead>
                <th scope="col" style="border-top: 0px">Name</th>
                <th scope="col" style="border-top: 0px">Rate</th>
                <th scope="col" style="border-top: 0px"></th>
                </thead>
                <tbody>
                <tr v-for="tax in user.clinic.taxes" :class="tax.global ? 'bg-light' : ''">
                  <td>{{ tax.name }}</td>
                  <td>{{ tax.rate }}%</td>
                  <td class="text-end" style="width: 1px; white-space: nowrap">
                    <span v-if="!tax.global">
                      <i class="far fa-trash cursor-pointer" @click="deleteClinicTax(tax)"></i>
                    </span>
                    <i v-else class="far fa-globe-europe text-primary"></i>
                  </td>
                </tr>
                <tr v-if="showAdd">
                  <td>
                    <input type="text"
                           v-model="newTax.name"
                           class="form-control form-control-sm"
                           placeholder="Enter a name for your tax..."
                           required
                    />
                  </td>
                  <td>
                    <div class="input-group input-group-sm">
                      <input type="number"
                             v-model="newTax.rate"
                             min="1"
                             max="99"
                             class="form-control form-control-sm" />
                      <div class="input-group-append">
                        <span class="input-group-text">%</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-end" style="width: 1px; white-space: nowrap">
                    <button class="btn btn-sm btn-light me-2" @click="cancelNewTax">
                      Cancel
                    </button>
                    <button class="btn btn-sm btn-primary"
                            :disabled="!newTax.name || !newTax.rate"
                            @click="saveNewTax">
                      Save
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <button class="btn btn-outline-primary float-end" @click="showAdd=true" v-if="!showAdd">
              <i class="far fa-plus me-1"></i>
              Add new Tax
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      taxes: [],
      showAdd: false,
      newTax: {
        name: "",
        rate: null,
      }
    };
  },
  methods: {
    saveNewTax() {
      if (!isNaN(this.newTax.rate) && this.newTax.rate > 0 && this.newTax.rate < 100) {
        this.$axios.post(process.env.VUE_APP_API_URL + "/settings/clinic/taxes",
            this.newTax,
        ).then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.cancelNewTax();
          this.$EventBus.$emit("refreshUser");
        });
      } else {
        alert('Rate must be between 1-99')
      }
    },
    deleteClinicTax(tax) {
      this.$axios.delete(process.env.VUE_APP_API_URL + "/settings/clinic/taxes/" + tax.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$EventBus.$emit("refreshUser");
          });
    },
    cancelNewTax() {
      this.newTax = {
        name: "",
        rate: null
      };
      this.showAdd = false;
    }
  },
  computed: {
    path: function () {
      return this.$router.currentRoute.fullPath;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
  mounted() {
  },
  components: {
  },
};
</script>



<style>
</style>
