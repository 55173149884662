<template>
  <div class="container-fluid">
    <div class="row">


      <!-- Tab cards -->
      <div class="col">
        <h4 class="fw-bold text-primary">Client Portal</h4>
        <p class="alert alert-primary">
          <i class="far fa-info-circle me-1"></i>
          Configure the client portal
        </p>
        <div class="row mb-3">
          <div class="col-md-6">
            <div
              class="card border-0 shadow-sm mb-4"
              id="user"
              v-if="displayOptions"
            >
              <div class="card-header bg-primary text-white">
                Display Options
              </div>
              <div class="card-body">

                <div class="d-block mb-1">
                  <toggle-button v-model="displayOptions.showLogo"
                                 class="my-auto"
                                 :color="'#03006b'" />
                  <label class="custom-control-label ms-1">
                    Show Clinic Logo
                  </label>
                </div>

                <div class="d-block mb-1">
                  <toggle-button v-model="displayOptions.showContact"
                                 class="my-auto"
                                 :color="'#03006b'" />
                  <label class="custom-control-label ms-1">
                    Show Contact Details
                  </label>
                </div>

                <div class="d-block mb-1">
                  <toggle-button v-model="displayOptions.showContactEmail"
                                 :disabled="!displayOptions.showContact"
                                 class="my-auto"
                                 :color="'#03006b'" />
                  <label class="custom-control-label ms-1">
                    Show Email Address
                  </label>
                </div>

                <div class="d-block mb-1">
                  <toggle-button v-model="displayOptions.showContactPhone"
                                 :disabled="!displayOptions.showContact"
                                 class="my-auto"
                                 :color="'#03006b'" />
                  <label class="custom-control-label ms-1">
                    Show Phone Number
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: [],
  data() {
    return {
      displayOptions: null,
    };
  },
  watch: {
    displayOptions: {
      handler: function(val) {
        this.updateDisplayOptions();
      },
      deep: true
    }
  },
  methods: {
    getDisplayOptions() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/settings/client-portal")
        .then(({ data }) => {
          if(data) {
            if(!data.settings) {
              this.defaultDisplayOptions();
            }
            else {
              this.displayOptions = data.settings.settings;
            }
          }
        });
    },
    updateDisplayOptions() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/settings/client-portal", {settings: this.displayOptions})
        .then(({ data }) => {
          console.log(data);
        });
    },
    defaultDisplayOptions() {
      let defaultDisplayOptions = {
        showLogo: true,
        showContact: false,
        showContactEmail: false,
        showContactPhone: false,
      };
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/settings/client-portal", {settings: defaultDisplayOptions})
        .then(({ data }) => {
          this.getDisplayOptions();
        });
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    },
  },
  mounted() {
    this.getDisplayOptions();
  },
  components: {

  },
};
</script>
<style>
</style>
