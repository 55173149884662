<template>
  <div class="card border-0 bg-light shadow-sm mb-4" id="invoices" v-if="user">
    <div class="card-body">

      <toggle-button v-model="user.clinic.allow_client_invoice_download"
                     @change="updateClinic"
                     class="my-auto"
                     :color="'#03006b'" />
      <label class="custom-control-label ms-1 mb-3">
        Allow clients to download invoices directly from their Client Portal
      </label>

      <h5>Invoice Instructions</h5>
      <div class="row mb-3">
        <div class="col">
          <p class="alert alert-primary">
            This information will appear on all of your receipts, and is a great
            place to add your full business name, VAT number, or address of
            record. Do not include any confidential or financial information
            such as credit card numbers.
          </p>
          <textarea
            v-model="user.clinic.extra_billing_information"
            class="form-control"
            rows="5"
            placeholder="Additional invoice instructions..."
          ></textarea>
        </div>
      </div>

      <div class="row text-end mt-3">
        <div class="col">
          <button class="btn btn-primary" @click="updateClinic">
            <i class="far fa-save me-2"></i>Update
          </button>
        </div>
      </div>

      <h5>Invoice Banner Image</h5>
      <div class="row">
        <div class="col">
          <p class="alert alert-primary">
            Upload an optional banner image to be displayed on your invoices.
          </p>

          <div v-if="showUploadInvoiceBanner">
            <vue-dropzone
              ref="clientDropZone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-success="uploadCompleted"
            ></vue-dropzone>
          </div>

          <div v-else>
            <div class="row">
              <div class="col text-center">
                <img
                  :src="bannerUrl"
                  style="max-height: 125px; max-width: 100%"
                  class="mx-auto"
                />
              </div>
            </div>

            <div class="row text-end mt-3">
              <div class="col">
                <button
                  class="btn btn-outline-danger me-2"
                  @click="removeBanner"
                >
                  <i class="far fa-times me-2"></i>Remove Image
                </button>
                <button
                  class="btn btn-primary"
                  @click="showUploadInvoiceBanner = true"
                >
                  <i class="far fa-upload me-2"></i>Upload Image
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  props: [],
  data() {
    return {
      showUploadInvoiceBanner: null,
      bannerUrl: null,
      allowClientInvoice: true,
    };
  },
  methods: {
    updateClinic() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/settings/clinic",
          this.user.clinic
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
        });
    },
    uploadCompleted(file, response) {
      console.log(response);
      this.user.clinic.invoice_banner_image = response.url;
      this.updateClinic();
      this.bannerUrl = response.url;
      this.showUploadInvoiceBanner = false;
    },
    removeBanner() {
      this.user.clinic.invoice_banner_image = null;
      this.updateClinic();
      this.bannerUrl = null;
      this.showUploadInvoiceBanner = true;
    },
  },
  computed: {
    dropzoneOptions() {
      return {
        url:
          process.env.VUE_APP_API_URL + "/settings/clinic/" + this.user.clinic_id + "/upload-invoice-banner",
        thumbnailWidth: 300,
        headers: {
          Authorization: "Bearer " + this.token,
        },
        dictDefaultMessage:
          "<i class='far fa-upload fa-4x text-primary'></i><br><br>Click here to select a file from your computer or drag n' drop a file to begin uploading",
        maxFiles: 1,
        acceptedFiles: "image/*",
      };
    },
    token() {
      return this.$store.getters['auth/token'];
    },
    path: function () {
      return this.$router.currentRoute.fullPath;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
  mounted() {
    this.showUploadInvoiceBanner = !this.user.clinic.invoice_banner_image;
    this.bannerUrl = this.user.clinic.invoice_banner_image;
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
};
</script>



<style>
</style>