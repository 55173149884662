<template>
  <div v-if="$can('manage clinic')" class="container-fluid">
    <div class="row">

      <!-- Tab cards -->
      <div class="col">
        <!-- Clinic Details -->
        <h4 class="fw-bold text-primary">Clinic Details</h4>
        <div class="card border-0 bg-light shadow-sm mb-4" id="details">
          <div class="card-body">
            <p class="alert alert-primary">
              Enter your clinic details here to be shown on invoices and
              appropriate client communications.
            </p>
            <form @submit.prevent="updateClinic">
              <div class="row mb-2">
                <div class="col">
                  <label>Clinic Name</label>
                  <input
                    type="text"
                    v-model="user.clinic.name"
                    class="form-control"
                    required
                  />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col">
                  <label for>Address</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Address Line 1"
                    v-model="user.clinic.address_1"
                  />
                </div>
                <div class="col">
                  <label class="text-light">Address 2</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Address Line 2"
                    v-model="user.clinic.address_2"
                  />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Town"
                    v-model="user.clinic.address_town"
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="city"
                    v-model="user.clinic.address_city"
                  />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="County"
                    v-model="user.clinic.address_county"
                  />
                </div>
                <div class="col">
                  <country-select
                    v-model="user.clinic.address_country"
                  ></country-select>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Post Code"
                    v-model="user.clinic.address_post_code"
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Telephone No."
                    v-model="user.clinic.phone"
                  />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col">
                  <label>Email Address</label>
                  <input
                    type="text"
                    v-model="user.clinic.email_address"
                    class="form-control"
                    required
                  />
                </div>
              </div>

              <div class="row text-end">
                <div class="col">
                  <button class="btn btn-primary" type="submit">
                    <i class="far fa-save me-2"></i>Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- CLINIC LOGO -->
        <h4 class="fw-bold text-primary">Clinic Logo</h4>
        <div class="card border-0 bg-light shadow-sm mb-4" id="logo">
          <div class="card-body">
            <p class="alert alert-primary">
              Your Clinic Logo will show on invoices and other
              communications with your clients.
            </p>
            <div class="row">
              <div
                class="col text-center"
                :class="showEditLogo ? 'd-none' : ''"
              >
                <div class="row mb-3">
                  <div class="col">
                    <img
                      :src="file"
                      :alt="user.name"
                      class="rounded-circle mb-3 me-2"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <label
                      for="file"
                      class="custom-file-upload"
                      @click="showEditLogo = true"
                    >
                      <i class="far fa-cloud-upload me-2 text-primary"></i
                      >Choose a file to upload
                    </label>
                    <input
                      type="file"
                      id="file"
                      ref="file"
                      @change="handleFileUpload"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col col-auto mx-auto small">
                    <p><b>Supported Files:</b> JPG, PNG, GIF (Max 10MB)</p>
                  </div>
                </div>
              </div>
              <div
                class="col text-center"
                :class="!showEditLogo ? 'd-none' : ''"
              >
                <div class="row">
                  <div class="col">
                    <h5>Crop & Zoom</h5>
                    <vue-croppie
                      ref="croppieRef"
                      :enableOrientation="true"
                      :boundary="{ width: 300, height: 300 }"
                      :viewport="{
                        width: 300,
                        height: 300,
                        type: 'circle',
                      }"
                      @result="result"
                      @update="update"
                    ></vue-croppie>
                  </div>
                </div>

                <div class="row mb-3 text-center">
                  <div class="col">
                    <button
                      class="btn btn-sm btn-light me-2"
                      v-tooltip.bottom="'Rotate Left'"
                      @click="rotate(-90)"
                    >
                      <i class="far fa-undo"></i>
                    </button>
                    <button class="btn btn-primary" @click="crop()">
                      <i class="far fa-save me-2"></i>Save Image
                    </button>
                    <button
                      class="btn btn-sm btn-light me-2"
                      v-tooltip.bottom="'Rotate Right'"
                      @click="rotate(90)"
                    >
                      <i class="far fa-redo"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <clinic-hours v-if="$can('manage availability for other users')"/>

        <h4 class="fw-bold text-primary">Clinic Invoice Settings</h4>
        <invoice-settings />

        <h4 class="fw-bold text-primary">Clinic Tax Rates</h4>
        <tax-rates />
      </div>
    </div>
  </div>
</template>

<script>

import CountrySelect from "../../../globals/CountrySelect";

import "croppie/croppie.css";
import InvoiceSettings from "./clinic-partials/InvoiceSettings";
import TaxRates from "@/views/areas/settings/areas/clinic-partials/TaxRates";
import ClinicHours from "./clinic-partials/ClinicHours";

export default {
  props: [],
  data() {
    return {
      users: [],
      form: {
        first_name: "",
        last_name: "",
        email: "",
        role: "practitioner",
      },
      availability_duration: "",
      availability_cooldown: "",
      availability_buffer: "",
      tzList: [],
      file: null,
      cropped: null,
      currentProfilePicture: "",
      uploadedUrl: "",
      showEditLogo: false,
      firstUploadWorkaround: true,
    };
  },
  methods: {
    updateClinic() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/settings/clinic",
          this.user.clinic
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser", data);
          this.$EventBus.$emit("alert", data);
        });
    },
    updateavailabilityDuration() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/clinic/update-availability-duration",
          {
            availability_duration: this.availability_duration,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser", data);
          this.$EventBus.$emit("alert", data);
        });
    },
    updateAvailabilityBuffer() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/clinic/update-availability-buffer",
          {
            availability_buffer: this.availability_buffer,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser", data);
          this.$EventBus.$emit("alert", data);
        });
    },
    updateAvailabilityCooldown() {
      this.$axios
          .post(
              process.env.VUE_APP_API_URL +
              "/settings/clinic/update-availability-cooldown",
              {
                availability_cooldown: this.availability_cooldown,
              }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("refreshUser", data);
            this.$EventBus.$emit("alert", data);
          });
    },
    fetchUsers() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/settings/clinic/fetch-clinic-users"
        )
        .then(({ data }) => {
          this.users = data;
        });
    },
    inviteUser() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/settings/clinic/invite-practitioner",
          this.form
        )
        .then(({ data }) => {
          this.form = {
            first_name: "",
            last_name: "",
            email: "",
            role: "",
          };
          this.$EventBus.$emit("alert", data);
        });
    },
    resendInvite(id) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/clinic/resend-invite-to-practitioner",
          { user_id: id }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    fetchAvailableTimezones() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/settings/available-timezones")
        .then(({ data }) => {
          this.tzList = data;
        });
    },

    async bind() {
      await this.$refs.croppieRef.bind({
        url: this.uploadedUrl,
      });
      // this.crop();
    },
    crop() {
      // Here we are getting the result via callback function
      // and set the result to this.cropped which is being
      // used to display the result above.
      let options = {
        format: "png",
        circle: true,
        size: "original",
      };
      this.$refs.croppieRef.result(options, (output) => {
        // this.cropped = output;
        this.file = output;
        this.submitFile();
      });
    },
    // EVENT USAGE
    cropViaEvent() {
      this.$refs.croppieRef.result(options);
    },
    result(output) {
      this.cropped = output;
    },
    update(val) {
      // console.log(val);
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },

    //
    base64ToBlob(base64, mime) {
      mime = mime || "";
      var sliceSize = 1024;
      var byteChars = window.atob(base64);
      var byteArrays = [];

      for (
        var offset = 0, len = byteChars.length;
        offset < len;
        offset += sliceSize
      ) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mime });
    },
    submitFile() {
      var base64ImageContent = this.file.replace(
        /^data:image\/(png|jpg);base64,/,
        ""
      );
      var blob = this.base64ToBlob(base64ImageContent, "image/png");
      var formData = new FormData();
      formData.append("file", blob);

      if (!this.firstUploadWorkaround) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/settings/clinic/upload-profile-picture",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(({ data }) => {
            this.file = data.url;
            this.showEditLogo = false;
            this.firstUploadWorkaround = true;
            this.$EventBus.$emit("refreshUser", data);
            this.$EventBus.$emit("alert", data);
          });
      } else {
        this.firstUploadWorkaround = false;
      }
    },

    /*
        Handles a change on the file upload
      */
    handleFileUpload() {
      // this.file = this.$refs.file.files[0];
      const files = this.$refs.file.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          this.uploadedUrl = e.target.result;
          this.bind();
          this.crop();
        };
        reader.readAsDataURL(files[0]);
      }

      this.$refs.croppieRef.bind({
        url: this.file.src,
      });

      // this.submitFile();
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
  mounted() {
    this.currentProfilePicture = this.user.clinic.photo_url ? this.user.clinic.photo_url : "";
    this.availability_duration = this.user.clinic.availability_duration ? this.user.clinic.availability_duration : "";
    this.availability_buffer = this.user.clinic.availability_buffer ? this.user.clinic.availability_buffer : "";
    this.availability_cooldown = this.user.clinic.availability_cooldown ? this.user.clinic.availability_cooldown : "";
    this.file = this.user.clinic.logo;
    this.fetchAvailableTimezones();
    this.fetchUsers();
    this.$refs.croppieRef.bind({
      url: this.currentProfilePicture,
    });
  },
  computed: {
    path: function () {
      return this.$router.currentRoute.fullPath;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    token() {
      return this.$store.getters['auth/token'];
    },
  },
  components: {
    ClinicHours,
    TaxRates,
    InvoiceSettings,

    CountrySelect,
  },
};
</script>



<style>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>
