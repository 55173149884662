<template>
  <div>
    <div class="row">
      <div class="col">
        <h4 class="fw-bold text-primary">Clinic Business Hours</h4>
        <div class="card border-0 bg-light shadow-sm mb-4" id="hours" v-if="user">
          <div class="card-body">
            <p class="alert alert-primary text-left text-start">
              Specify your Clinic's business hours. This is for display purposes only and does not restrict bookings.
            </p>

            <div class="d-flex mb-2 w-100">
              <div class="my-auto">
                Clinic Business Hours
              </div>
              <div class="ms-auto ms-auto my-auto">
                <WeekSchedulePresetSelector v-model="clinic.business_hours" />
              </div>
            </div>
            <div class="card bg-white">
              <div class="card-body pt-2">
                <WeekScheduleSelect v-model="clinic.business_hours"
                                    :is-preset-selection="true" />
                <div class="d-flex">
                  <button class="btn btn-light ms-auto ms-auto me-2 me-2" @click="savePreset(clinic.business_hours)">
                    <i class="far fa-cloud-upload me-1 me-1" />
                    Save as Preset
                  </button>
                  <button class="btn btn-primary" @click="saveClinicHours">
                    <i class="far fa-save me-1 me-1" />
                    Update Clinic Hours
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <h4 class="fw-bold text-primary">Practitioner Working Hours</h4>
        <div class="card border-0 bg-light shadow-sm mb-4" id="hours" v-if="user">
          <div class="card-body">
            <p class="alert alert-primary text-left text-start">
              Specify working hours for each practitioner in your clinic. This is optional and can be overridden for each service which they offer.
            </p>
            <div class="d-flex mb-2 w-100">
              <div class="my-auto">
                <select-user
                    v-model="selectedUser"
                    class="w-100 d-block"
                />
              </div>
              <div class="ms-auto ms-auto my-auto" v-if="selectedUser">
                <WeekSchedulePresetSelector v-model="selectedUser.working_hours" />
              </div>
            </div>
            <div class="card bg-white">
              <div class="card-body pt-2">
                <p v-if="!selectedUser" class="text-center mt-2">Please select a practitioner</p>
                <div v-else>
                  <WeekScheduleSelect v-model="selectedUser.working_hours"
                                      :is-preset-selection="true" />
                  <div class="d-flex">
                    <button class="btn btn-light ms-auto ms-auto me-2 me-2" @click="savePreset(selectedUser.working_hours)">
                      <i class="far fa-cloud-upload me-1 me-1" />
                      Save as Preset
                    </button>
                    <button class="btn btn-primary" @click="saveUserHours">
                      <i class="far fa-save me-1 me-1" />
                      Update Working Hours
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SaveWorkingHoursPresetModal
        v-if="presetToSave"
        :preset="presetToSave"
        @closed="presetToSave=null"
        @saved="presetToSave=null" />
  </div>


</template>

<script>
import WeekSchedulePresetSelector from "../../../../components/working-hours/WeekSchedulePresetSelector";
import WeekScheduleSelect from "../../../../components/working-hours/WeekScheduleSelect";
import SelectUser from "@/views/components/users/SelectUser";
import SaveWorkingHoursPresetModal from "../../../../components/working-hours/SaveWorkingHoursPresetModal";
export default {
  props: [],
  data() {
    return {
      clinic: this.$store.getters['auth/user'].clinic,
      selectedUser: this.$store.getters['auth/user'],
      presetToSave: null
    };
  },
  methods: {
    saveClinicHours() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/settings/clinic",
            this.clinic
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
        });
    },
    saveUserHours() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/settings/user/" + this.selectedUser.id + "/update-working-hours",
            this.selectedUser
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
        });
    },
    savePreset(presetData) {
      this.presetToSave = presetData;
      $("#saveWorkingHoursPresetModal").modal("show");
    }
  },
  computed: {
    path: function () {
      return this.$router.currentRoute.fullPath;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  filters: {
  },
  mounted() {
  },
  components: {
    SaveWorkingHoursPresetModal,
    SelectUser,
    WeekScheduleSelect,
    WeekSchedulePresetSelector
  },
};
</script>



<style>
</style>